import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';



export default class Logout extends Component {
	 constructor(props) {
    super(props);
	 this.state = {redirect:false,pagename:''}
	 }
	componentDidMount() {
		var strusername="",strpawd="";
		if(localStorage.getItem("KLuername")!==null)
		{
			strusername=localStorage.getItem("KLuername");
			strpawd=localStorage.getItem("KLpassword");
		}
		localStorage.clear();
		localStorage.setItem("KLuername",strusername);
		localStorage.setItem("KLpassword",strpawd);
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
	}
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>
</React.Fragment>
)
	}
}



