import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 

import Serverurl from './Apiurl';
import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles'; 






import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
 
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default class  changepassword extends Component {


 constructor(props) {
    super();

    this.state = { redirect:false,pagename:'',OldPassword: '' ,NewPassword:'',ConfirmNewPassword:'',
	
	btndisabled:false,
submitbtn:"Change Password",showPassword:false,NewshowPassword:false,
OldshowPassword:false,errormessage:'',erropen:false,
	};
   
}




myChangeHandler = (event) => {
	
	 
	  
  let nam = event.target.name;
  let val = event.target.value;
    
  this.setState({[nam]: val});
  
  
}



componentDidMount() {
	
	 
	document.title = "Kalisu Foundation - Change Password";
		
		if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
} 
validateForm() {
    return (
      this.state.OldPassword.length > 0 &&
      this.state.NewPassword.length > 0 &&
      this.state.NewPassword === this.state.ConfirmNewPassword
    );
}



 
  
handleChange = event => {
let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 };

 handleChangeClick =  (event) => {
    event.preventDefault();
   let ur_id = localStorage.getItem(" ");
   let OldPassword = this.state.OldPassword;
 
   let NewPassword = this.state.NewPassword;
  // let ConfirmNewPassword = this.state.NewPassword;
   let ConfirmNewPassword = this.state.ConfirmNewPassword;
   
   if(OldPassword==="")
{
	this.setState({errormessage:"Please Enter Old Password.",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
	
}
   if(NewPassword==="")
{
	
	this.setState({errormessage:"Please Enter New Password.",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
	
}
   
    if(ConfirmNewPassword==="")
{
	
	this.setState({errormessage:"Please Enter Confirm Password.",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
}
  
  if(NewPassword!==ConfirmNewPassword)
  {
	
  this.setState({errormessage:"Please Re-Type Correct Password.",erropen:true});
		 window.scrollTo({
          top: 0
      })
		 return false;
  }
    this.setState({btndisabled:true,submitbtn:"Processing..."}) 
var formData = new FormData();

    formData.append('action','changepaswrd')
	formData.append('ur_id',localStorage.getItem("KLUserid"))
	formData.append('oldpassword',OldPassword)
	formData.append('newpassword',NewPassword)

	
	
	
 axios.post(Serverurl+"ajaxuser.php", formData, {
        }).then(res => {
			if(res.data=== "0")
				
 { this.setState({errormessage:"Your current password is not valid!",erropen:true,
 btndisabled:false,
	 submitbtn:"Change Password",
 });
	 

 }
 else
 {
	let myItem = localStorage.getItem('KLuername');
	let myItem1 = localStorage.getItem('KLpassword');
localStorage.clear();
localStorage.setItem('KLuername',myItem);
  localStorage.setItem('KLpassword',myItem1);
	
	 this.setState({errormessage:"Password has been updated successfully!",erropen:true,
			   btndisabled:false,
	 submitbtn:"Change Password",
	 OldPassword:'',
NewPassword:'',
ConfirmNewPassword:'',redirect: true,
			pagename:'/Login'	 
	 })
	 

			  


		  
 }
    
	});
    
    
  };

 handleClickShowPassword = () => {
    
	this.setState({
            showPassword: !this.state.showPassword
          })
  };
  
  handleClickOldShowPassword = () => {
    
	this.setState({
            OldshowPassword: !this.state.OldshowPassword
          })
  };
  
  
   handleClickNewShowPassword = () => {
    
	this.setState({
            NewshowPassword: !this.state.NewshowPassword
          })
  };
	
	  handleMouseDownPassword = event => {
    event.preventDefault();
  };

	  handleMouseDownPassword1 = event => {
    event.preventDefault();
  };	


  handleMouseDownPassword2 = event => {
    event.preventDefault();
  };
  
   handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  } 
render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    } 
return (

  
<React.Fragment>




<p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>

<div className="changepassword">
<form className={useStyles.form} onSubmit={this.handleChangeClick}>


	
	
		<p>  <Collapse in={this.state.erropen}>
					<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse></p>
			  
	<p><span className="validation">*</span>Old Password </p>
		 
					  
			<p className="chngtxt"><FormControl fullWidth  variant="outlined">
			 
					  <OutlinedInput value={this.state.OldPassword}
            id="OldPassword" name="OldPassword" 
			
			type={this.state.OldshowPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
			inputProps={{tabIndex: "1",maxLength:15}} InputLabelProps={{ shrink: true }}  
			size="small"
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickOldShowPassword}
                  onMouseDown={this.handleMouseDownPassword}  >
				  { this.state.OldshowPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
         
			</FormControl>	</p> 
		  
		  
		  


		<p ><span className="validation">*</span>New Password </p>
					 
					  
					  
							  
			<p className="chngtxt"><FormControl fullWidth  variant="outlined">
 
          <OutlinedInput value={this.state.NewPassword}
            id="NewPassword" name="NewPassword" 
			
			type={this.state.NewshowPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
			inputProps={{tabIndex: "2",maxLength:15}} InputLabelProps={{ shrink: true }}  
			size="small"
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickNewShowPassword}
                  onMouseDown={this.handleMouseDownPassword1}  >
				  { this.state.NewshowPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
           />
         
			</FormControl>	</p> 


			<p><span className="validation"> * </span> Confirm Password </p>

					  
							  
			<p className="chngtxt"><FormControl fullWidth  variant="outlined">
			 
			<OutlinedInput value={this.state.ConfirmNewPassword}
            id="ConfirmNewPassword" name="ConfirmNewPassword" 
			
			type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.myChangeHandler}
			inputProps={{tabIndex: "3",maxLength:15}} InputLabelProps={{ shrink: true }}  
			size="small"
            endAdornment={<InputAdornment position="start"> <IconButton
                     onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword2}  >
				  { this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                
                </IconButton></InputAdornment>}
				/>
					 
					</FormControl>	</p>  
					
	<p><Button
            type="submit"
           
			disabled={this.state.btndisabled}
            variant="contained" 
            color="primary"
         
            className="chsubmitbtn"
          >
            {this.state.submitbtn}
          </Button></p>
			

		 




</form></div>

</React.Fragment>




     );
    }
   }



  