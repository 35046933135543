import React, { Component } from "react";

import { Navigate  } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';



import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Kalisulogo  from './images/logo.jpg';
//import EmailIcon from './images/mail-icon.jpg';

import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';



import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

export default class login extends Component{
	constructor(props){
		super(props);
			this.state={btndisable:false,btntext:'Sign In',Username:'',Password:'',pagename:'',errormessage:'',open:false,checked:true,Showpassword:false}
		}
	
	myChangeHandler=(event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	
	handleChange=(event)=>
	{
		this.setState({checked: !this.state.checked});
	};
	
	handleClickShowpassword=(event)=>
	{
		this.setState({showPassword: !this.state.showPassword});
	};
	
	
	componentDidMount()
	{
		if(localStorage.getItem("KLUserid")!==null)
		{
			this.setState({
				redirect:true,
				pagename:'/User'
			})
		}
		else{
		
		this.setState({
							Username: localStorage.getItem("KLuername"),
							Password: localStorage.getItem("KLpassword"),
						  }) 
		}
	}
	handleClickLogin=(event)=>
	{
		var self=this;
		event.preventDefault();
		let Username=this.state.Username;
		let Password=this.state.Password;
		let ischeck=this.state.checked;
		
		if(Username===''){
			
			this.setState({errormessage:"Please Enter Your Username",open:true});
			 window.scrollTo({top : 0  })
			 return false;
		}
		if(Password===''){
			this.setState({errormessage:"Please Enter Your Password",open:true});
			window.scrollTo({top:0})
			return false;
		}
	
	this.setState({btndisable:true,btntext:'Processing...',});
	var formData=new FormData();
	formData.append('action','Login')
	formData.append('ur_username',Username)
	formData.append('ur_password',Password)
	axios.post(Serverurl+"ajaxuser.php", formData, {
				}).then(res => {
				
				
				if(res.data.length>0){
					
					if(ischeck===true)
					  {
						  
						  localStorage.setItem("KLuername",Username);
						  localStorage.setItem("KLpassword",Password);
						
					  }
					  else{
							 localStorage.removeItem("KLuername");
							  localStorage.removeItem("KLpassword"); 
						  }
				 localStorage.setItem("KLUserid",res.data[0].ur_id);
				localStorage.setItem("KLRole",res.data[0].ur_role);
				localStorage.setItem("KLName",res.data[0].ur_name);
				if(res.data[0].ur_role==="1")
				{
				
		self.setState({redirect:true,open:false,btndisable:false,btntext:'Signin',pagename:'/Dashboard' });
				}
				else{
					
				self.setState({redirect:true,open:false,btndisable:false,btntext:'Signin',pagename:'/Dashboard' });
			
				}
				}
				else
				{
					
					self.setState({errormessage:"Not a valid login details",open:true,btndisable:false,btntext:'Signin'});
		 window.scrollTo({  top: 0     })
		 
				}
				
				});
				
				
			
	}
				
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
	
	
return (




			
<React.Fragment>
					
	<div className="loginbg">
		<div className="loginauto">
	
		
					
					<div className="logo">
						<img src={Kalisulogo}/>
						
					</div>

				
					<div className="signin">
						
						<h2>Sign in</h2>
						<p>Welcome back! Please enter your details</p>
						
					</div>
					
					
				
					
					
					<div className="logindeatils">
 
  <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
 
				<p>Email</p><p><TextField 
					InputProps={{
				  startAdornment: (
					<InputAdornment position="start">
					
					  <MailOutlineTwoToneIcon />
					</InputAdornment>
				  ),
				}}
				sx={{
						"& input": {
							color: '#cbc7f9',
							
						}
					}}
				placeholder="SX + InputProps"
				

					className= "textbox"
					id="textemail" 
					type=""
					placeholder= "Enter Your email"  
					fullWidth   variant="standard" name="Username" value={this.state.Username}  inputProps={{tabIndex: "1",maxLength:150}} onChange={this.myChangeHandler} /></p>
					

			
	
					  
					<p>Password</p><p><TextField 
					 InputProps={{
					
					
						  startAdornment: (
							<InputAdornment position="start">
							  <LockTwoToneIcon />
							</InputAdornment>
						  ),
						}}
            
            
							
					
					sx={{
						"& input": {
							color: '#cbc7f9',
							
						}
					}}
					placeholder="SX + InputProps"
					type="password"

					className= "textbox"
					id="txtPassword" 
					
					 placeholder= "Enter Your Password"  
					fullWidth   variant="standard" name="Password"  value={this.state.Password}  inputProps={{tabIndex: "1",maxLength:150}} onChange={this.myChangeHandler} /></p>
					
					<p> <FormControlLabel
						label="Remember  for 30 days"
						control={ <Checkbox  checked={this.state.checked}  onChange={this.handleChange}  inputProps={{ 'aria-label': 'controlled' }} />}
					  />
					  </p>
					  
					  
							<p>
							<Button
							type="submit"
							fullWidth
							
							disabled={this.state.btndisable}
							className="primarybtn"
							onClick={this.handleClickLogin}
							
						  >{this.state.btntext}
							
				   

				   </Button></p>
 
				




			</div>

	<div className="clear"></div>
			
				
		</div>		
	</div>


</React.Fragment>




);

	}		


}





						  
