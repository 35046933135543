import React ,{Component} from 'react'; 

import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';






import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';



import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';

const headCells = [

	{ id: 'ct_name', numeric: false, label: 'Name' },
 { id: '', numeric: false, label: '' },
 
 ]

function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});





export default class Categoryform extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,
     categorydata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],
	 
	  order:'desc' ,orderBy:'',crid:'',cname:'',btnsrchdisbaled:false,btnsearchtxt:'Serach',
	          
      }
	   this.updateDonenewParent = this.updateDonenewParent;
	}
	  
	
	updateDonenewParent = (text) => {this.apicall(this.state.page,this.state.rowsPerPage);  }
	
	
handleClickSearch=()=>{   
 
  
this.setState({  
      page: 0,
      rowsPerPage:20,btnsrchdisbaled:true,btnsearchtxt:'Processing...',
	  prevpage:0,Userdata:[]
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});

	
} 
	myChangeHandler = (event) => {
		
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
} 







	componentDidMount() {
		document.title = "Kalisu Foundation - Category Management";
	if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}else{
			if(localStorage.getItem("KLRole")!=="1")
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
			
		}
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);

} 




apicall(page,rowsPerPage){
	
	

 
			 axios.get(Serverurl+'ajaxcategory.php?action=getall&ct_name='+this.state.cname+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  categorydata: resp.data,  btnsrchdisbaled:false,btnsearchtxt:'Search',
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({categorydata:[],totaldata:0,btnsrchdisbaled:false,btnsearchtxt:'Search',});
	 }
 
    });
}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
   
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  
    handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};
  
  userDelete(ctid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('ct_id',ctid)
	 	   
formData.append('userid',localStorage.getItem("KLUserid"))	
	
	
	 axios.post(Serverurl+"ajaxcategory.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,page:0
    
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);});
   
 

  
    
            });
 }
      }
 
  
  
 Useraddbox(val){
        this.setState({ ctid: val });  
  
         setTimeout(() => this.refs.Addcategorybox.handleClickOpen(),500);

      }



render() {
return(
 
 <React.Fragment>

 
  
 
 <div className="contentbg toppad">
		<div className="userserach">
			<ul>
				
						
<li><p>Name</p>
			<p ><TextField
				className="inputcntrl"		
					
             value={this.state.cname}
			inputProps={{tabIndex: "1",maxLength:150}}
			
           name="cname"  onChange={this.myChangeHandler}
			
          /></p>
	</li>
		
			
			
			
		
		<li>
		<p>&nbsp;</p>
		<p><Button className="searchbtn" disabled={this.state.btnsrchdisbaled}  variant="contained" onClick={this.handleClickSearch}  >
			{this.state.btnsearchtxt}
			</Button></p></li>	
			
			<li>
		<p>&nbsp;</p>
		<p><Button variant="contained"  className="Newbtn" onClick={()=>{this.Useraddbox(0)}} endIcon={<PersonAddOutlinedIcon className="iconvertical"/>}	 >
				Add Category	
		</Button></p></li>	
			
			
		</ul>


			
				
		
		</div>
 		

<div className="clear"></div>

<Addcategorybox ref="Addcategorybox" id={this.state.ctid}  updateDonenew={this.updateDonenewParent}/>

            
	
  <Paper>
  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.categorydata.length}
            />
                      <TableBody>
                     {stableSort(this.state.categorydata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.ct_name}</TableCell>

<TableCell align="left">
<div className="actionbtns">
<Button variant="text" className="editbutton" onClick={()=>{this.Useraddbox(item.ct_id)}} startIcon={<EditIcon />}>
Edit
</Button>


{item.catcnt ==="0" &&(<React.Fragment> 
 
<Button variant="text"    className="deletebutton"   onClick={()=>{this.userDelete(item.ct_id)}} startIcon={<DeleteIcon />}>
  Delete
</Button> 
 </React.Fragment>)}
 </div>

 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="2">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[20,50,100,150]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}  
      />   
  
    

  </Paper>

</div>

 </React.Fragment>
 



);


		}
	
}



/*child component */

export  class  Addcategorybox extends Component {

   constructor(props) {
      super(props)
	  
          this.state = {redirect: false,
     categorydata:[],
	 errormessage:"",erropen:false,name:'',btnsavedisbaled:false,btnsavetxt:'Save',
	  order:'desc' ,orderBy:'',cname:'',
	          
      }
      
  }
  
  
   Usergetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxcategory.php?action=getall&ct_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      ct_id:resp.data[0].ct_id,
	 cname:resp.data[0].ct_name,
     
	
    
    });
	
  }
  
});
	  
  }else{
	 this.setState({  
	  cname:'',
		ct_id:0,
	
	  }); 
 }
  
    }
  
  
  handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open, cname:'', 
     errormessage:'',erropen:false, 
	  });
		this.Usergetdata() ;
};
  
   
myChangeHandler = (event) => {
	
	
  let nam = event.target.name;
  let val = event.target.value;  
 
 
  this.setState({[nam]: val});

  

}
  
  handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }
  
  
  
  
SubmitForm= (e) => {

	let cname = this.state.cname;


	if(cname===''){
						
		this.setState({errormessage:"Please Enter Name.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
		
			
this.setState({errormessage:"",erropen:false,btnsavedisbaled:true,btnsavetxt:'Processing...',});

				var formData = new FormData();
				if(this.props.id===0){
			    formData.append('action','insert')
				
				}
				
				else{
					formData.append('action','update')
					formData.append('ct_id',this.props.id)
				}
				
			
					formData.append('ct_name',cname)
				
				
					formData.append('userid',localStorage.getItem("KLUserid")) 
					axios.post(Serverurl+"ajaxcategory.php", formData, {
				}).then(res => {
					
						if(res.data!=="-1")
					{
						
					
					
					
					
					  this.props.updateDonenew();
						this.setState({open:false,btnsavedisbaled:false,btnsavetxt:'Search',errormessage:"",erropen:false});
					
						
					
					}
					else{
						
				this.setState({open:false,btnsavedisbaled:false,btnsavetxt:'Save',errormessage:"Category already Exist",erropen:true});
					}
				
					
	} );

					
}
	
  
render (){

return(
<React.Fragment>





    <Dialog open={this.state.open} onClose={this.SubmitForm} maxWidth="sm" fullWidth="true"  aria-labelledby="form-dialog-title">
       <div className="dialoguetitle"><h4>Category</h4></div>
      <DialogContent>
	  
<div className="userdialogue">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>	
		<p><span className="validation">*</span>Name</p>
		<p><TextField name="cname" 
	   onChange={this.myChangeHandler} 
		
	    InputLabelProps={{
		shrink: true,
		}}inputProps={{tabIndex: "1",maxLength:150}}
	  fullWidth className="inputcntrl"
	   value={this.state.cname}
			 
			 
				
			/></p>
						
					

							
				
     <div className="dialoguebtn">
			<ul>	  
			
			
		
		<li><Button  className="cancelbtn" onClick={this.handleClickOpen} color="secondary">
					Cancel
				</Button></li>
		<li><Button className="savebtn" disabled={this.state.btnsavedisbaled}  onClick={this.SubmitForm} color="secondary">
					{this.state.btnsavetxt} 
		</Button></li>
				
				
			</ul>	
<div className="clear"></div>

 </div>     
			 
 </div> 		
 </DialogContent>
      
    </Dialog>
</React.Fragment>

) }
  
  
  
  
  
  
  
  
}

