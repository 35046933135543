
import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 

import Serverurl from './Apiurl';
import Button from '@mui/material/Button';



import { styled } from '@mui/material/styles';

import TextField from '@mui/material/TextField';



import Select from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import { Editor } from "@tinymce/tinymce-react";
   import CloudUploadIcon from '@mui/icons-material/CloudUpload';
   	const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
  export  class  AudioForm extends Component {

   constructor(props) {
      super(props);
	  
          this.state = {redirect: false,
     audiodata:[],btnaudisbaled:false,btnautxt:'Submit',
	 errormessage:"",erropen:false,name:'',content:'',
	  order:'desc' ,orderBy:'' ,af_title:'',af_description:'',af_ct_id:'',
	      categoryid:'',   audioarray:[],categoryarray:[],af_file:'',previocn:'',
      }
      this.handleEditorChange = this.handleEditorChange.bind(this); 
  }
  
  
  handleEditorChange=(content) =>{
	  // alert(content)
    this.setState({ content });
  }
categorybind()
{
	axios.get(Serverurl+'ajaxcategory.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						categoryarray:resp.data	
							}) 
							   this.Audiogetdata() ;
			}
			
	   }) .then(data => {
        let categoryFromApi = this.state.categoryarray.map(row => {
                          
          return {value: row.ct_id, display:row.ct_name}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display:'Select Category'}].concat(categoryFromApi)
        });
      })

}
  
    Audiogetdata() {
		
	 if(localStorage.getItem("KLafid")!==null)
		
  {
  
   axios.get(Serverurl+'ajaxaudio.php?action=getall&af_id='+localStorage.getItem("KLafid"))
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
     af_id:resp.data[0].af_id,
	 af_title:resp.data[0].af_title,
	content:resp.data[0].af_description,
	previocn:resp.data[0].af_file,
    categoryid:resp.data[0].af_ct_id,
	
	af_file:resp.data[0].af_file,
    });
	
  }
  
});
	  
  }
	
 /* else{
	 this.setState({  
	  af_title:'',
	  
		af_id:0,
	 
		
	
	  }); 
 } */
  
  }   
  
  
  

componentDidMount() {
	
	document.title = "Kalisu Foundation - Audio Management";
	if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}else{
			if(localStorage.getItem("KLRole")!=="1")
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
			
		}

	   this.categorybind();
	   
	   
//this.setState({redirect: true,
						//	pagename:'/Audiofile'}) 
		
}
    handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }
  
  
  SubmitForm= (e) => {


	let af_title = this.state.af_title;
	let af_description=this.state.content;
	let categoryid=this.state.categoryid;
	
	let af_file=this.state.af_file;



if(af_title===''){
						
		this.setState({errormessage:"Please Enter Title.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
		
		
		
			if(categoryid===''){
						
		this.setState({errormessage:"Please Select Category ",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
			
				
				
			if(af_file===''){
						
		this.setState({errormessage:"Please Upload Audi File",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
					if(af_description===''){
						
		this.setState({errormessage:"Please Enter Description.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}

		this.setState({errormessage:"",erropen:false,btnaudisbaled:true,btnautxt:'Processing...',});
		
				var formData = new FormData();
				if(localStorage.getItem("KLafid")===null){
				formData.append('action','insert')
				}
				
				
				else{
					formData.append('action','update')
					formData.append('af_id',localStorage.getItem("KLafid"))
				}
				
					formData.append('af_description',af_description)
					formData.append('af_ct_id',categoryid)
					formData.append('af_title',af_title)
				
				
	if(this.state.af_file!=="")
	  {
		  
		for (const key of Object.keys(this.state.af_file)) {
            formData.append('Filedata', this.state.af_file[key])
        }
 	
	   formData.append('af_file','1')		
	  }else
	  {
		 formData.append('af_file',this.state.previocn)	  
	  
	  }
	
	
	
				
				
					formData.append('userid',localStorage.getItem("KLUserid")) 
					axios.post(Serverurl+"ajaxaudio.php", formData, {
				}).then(res => {
					
						if(res.data!==-1)
					{
						
				
					
					
					
						this.setState({btnaudisbaled:false,btnautxt:'Submit',redirect:true,pagename:'/ManageAudio'});
						
							
							
						}
					
					
				})
  
					
  }
					

	
     
myChangeHandler = (event) => {
	
	
  let nam = event.target.name;
  let val = event.target.value;  
 
 
  this.setState({[nam]: val});

  

}


  onFileChange=(e) =>{
	   	 
	   
	
	   let imageFile = e.target.files[0];
	 
	 
  if (!imageFile.name.match(/\.(mp3|mp4|waw|flac|alac|ogg)$/)) {
 
  this.setState({errormessage:"Please Upload Valid Audio File (mp3,mp4,WAw,FLAC,ALAC,OGG).",erropen:true});
		 window.scrollTo({        top: 0     })
	
  
    return false;
  }else{
        this.setState({ af_file: e.target.files })
  }
		
    }
  
handleClickOpen= (e) => {
 this.setState({redirect:true,pagename:'/ManageAudio'});
}

   
   
render (){
	
	if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

return(



 
 



<React.Fragment><div className="formHeading">
<div className="pageheading"><h4>Audio Management</h4>
						</div>

<div className="backbutton">
	<Button  className="afbackbutton" variant="contained"  onClick={this.handleClickOpen}  >
			BACK
</Button>  
</div>	<div className="clear"></div></div>
<div className="userform contentbg">


<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>	
<ul>
	  <li>
			<p><span className="validation">*</span>Title</p>
			<p><TextField name="af_title" 
		   onChange={this.myChangeHandler} 
		inputProps={{tabIndex: "1",maxLength:150}}
		   
		 	className="inputcntrl"		
		   value={this.state.af_title}
				
			ref={this.usernameInput}
					
			/></p>
		</li>
						
			<li>
		<p><span className="validation">*</span>Category</p>	
		<p><FormControl  >			   
			<Select
			 onChange={this.myChangeHandler} 
		inputProps={{tabIndex: "2",}}
		   
		 	className="inputcntrl"	
			native
			value={this.state.categoryid}
			onChange={this.myChangeHandler}
			name='categoryid'
			id='id'
			fullwidth
			 defaultValue={{  value:this.state.categoryid }}
			InputLabelProps={{
			shrink: true,
			}}
			>
		
		
			{this.state.categoryarray.map(row => (
				<option  
            
				  value={row.value}
				>
				  {row.display}
				</option  >
			  ))}
			 </Select>
		</FormControl></p>	
	</li>		
		
			
			
		 <li>
			<p><span className="validation">*</span>File </p>
			 <p className="upload-submitimg">
			 
			 <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file"  name="af_file"  onChange={this.onFileChange}/>
    </Button>
			 

			<br/>{this.state.previocn!=="" &&(
			<p><a href={Serverurl+'uploads/'+this.state.previocn} target='_blank' >view</a></p>
	
			)}
			</p>	 
	 </li>
			
			
			
		
		
		
			<div className="clear"></div>
		<li style={{"width":"100%","display":"block","float":"none"}}>
			<p><span className="validation">*</span>Description</p>
			<p>
			
			
			<Editor           apiKey={`0l9ca7pyz0qyliy0v9mmkfl2cz69uodvc8l6md8o4cnf6rnc`} init={{
           height: 500,
           menubar: false,
           plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
           ],
           toolbar: 'undo redo | formatselect | Table | image |' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}

			value={this.state.content}  onEditorChange={this.handleEditorChange}/>	</p>
		</li>			
				
 
		
		<li>
			<p><Button  className="afsubmitbtn"  onClick={this.SubmitForm} color="secondary">
				  Submit
				</Button>
			</p>
		</li>
		
</ul>
       </div>
	<div className="clear"></div>	
 	</React.Fragment>

			
	);
}
  }

export default AudioForm;
