import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';



import Select from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
 





import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
const headCells = [


 
 
  
 { id: 'ur_name', numeric: false, label: 'Name' },
   { id: 'ur_username', numeric: false, label: 'User Name' },
      { id: 'ur_password', numeric: false, label: 'Password' },
   { id: 'ur_role', numeric: false, label: 'Role' },

   { id: 'ur_phonenumber', numeric: false, label: 'Phone No.' },
 
  { id: '', numeric: false, label: '' },
 
 ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});


export default class Usermaster extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,
     userdata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],pageheading:'User Management',
	 
	  order:'' ,orderBy:'',name:'',urpassword:'',phonenumber:'',role:'',username:'',btnsrchdisbaled:false,btnsearchtxt:'Serach',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }






	componentDidMount() {
	


	 
		
	
	
	document.title = "Kalisu Foundation - User Management";
	
	if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}else{
			if(localStorage.getItem("KLRole")!=="1")
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
			
		}
	
	
	
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);

} 


  


handleClickSearch=()=>{   
 
  
  this.setState({  
      page: 0,
      rowsPerPage:20,btnsrchdisbaled:true,btnsearchtxt:'Processing...',
	  prevpage:0,Userdata:[]
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});
	

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	/*	 axios.get(Serverurl+'ajaxuser.php?action=getall&ur_name='+this.state.name+'&ur_role='+this.state.role+'&ur_password='+this.state.urpassword+'&ur_phonenumber='+this.state.ur_phonenumber+'&ur_username='+this.state.ursername+' &StartIndex='+page+'&PageSize='+rowsPerPage)*/

     
			 axios.get(Serverurl+'ajaxuser.php?action=getall&ur_name='+this.state.name+'&ur_role='+this.state.role+'&ur_username='+this.state.username+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  userdata: resp.data,  btnsrchdisbaled:false,btnsearchtxt:'Search',
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({userdata:[],totaldata:0,  btnsrchdisbaled:false,btnsearchtxt:'Search',});
	 }
 
    });
}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
 
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

 userDelete(urid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('ur_id',urid)
	 	   
formData.append('userid',localStorage.getItem("KLUserid"))	
	
	
	 axios.post(Serverurl+"ajaxuser.php", formData, {
        }).then(res => {
    
             this.setState({  
      prevpage: -1,page:0,
    
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});
   
 

  
    
            });
 }
      }

      Useraddbox(val){
        this.setState({ urid: val });  
  
         setTimeout(() => this.refs.AddUserbox.handleClickOpen(),500);

      }

render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
 <React.Fragment>



 <div className="contentbg toppad">
 
 <div className="userserach">
 <ul>
 
	
			
<li><p>Name</p>
			<p ><TextField
				className="inputcntrl"	
					
             value={this.state.name}
			inputProps={{tabIndex: "1",maxLength:150}}
			
            name="name" onChange={this.myChangeHandler}
			
          /></p>
	</li>

		

		 <li>
			<p>User Name</p>
			<p><TextField
				className="inputcntrl"	
				 value={this.state.username}
				inputProps={{tabIndex: "1",maxLength:150}}
				
				name="username" onChange={this.myChangeHandler}
			
			
          /></p>
		</li> 
			
	
				
		<li>
		<p> Role</p>
		<p><FormControl  sx={{ m: 0, width: 160 }} className="inputcntrl"	>			   
		<Select
		
		native
		value={this.state.role}
		onChange={this.myChangeHandler}
		name='role'
		id='role'
		
		>
		<option value={0}>&nbsp;Role</option>
		<option value={1}>Admin</option>
		<option value={2}>Co-Ordinator</option>		
		</Select>
		</FormControl></p>	
		</li>

        <li>
		<p>&nbsp;</p><p>
		<Button className="searchbtn" disabled={this.state.btnsrchdisbaled} variant="text" onClick={this.handleClickSearch}  >
        {this.state.btnsearchtxt}
		</Button> </p></li>

        <li><p>&nbsp;</p><p>
		<Button variant="contained"  className="Newbtn" onClick={()=>{this.Useraddbox(0)}} 	endIcon={<PersonAddOutlinedIcon className="iconvertical"/>} >
				Add User	
		</Button>
		</p>
		
				
		</li>		
	
		</ul>
		<div className="clear"></div>
  
  </div>
  
  <div className="clear"></div>

     
		
	



<AddUserbox ref="AddUserbox" id={this.state.urid}  updateDonenew={this.updateDonenewParent}/>

	
         <Paper sx={{ width: '100%', mb: 2 }}>     
	
	
  <TableContainer className={useStyles.container}>  
     

        
        <Table stickyHeader aria-label="sticky table"> 
                    
          		
<TableHead>

					
	<TableRow>

      		
                      </TableRow></TableHead>
			
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.userdata.length}
            />
		
                      <TableBody>
                     {stableSort(this.state.userdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.ur_name}</TableCell>
<TableCell>{item.ur_username}</TableCell>
<TableCell>*****</TableCell>
<TableCell>{item.role}</TableCell>


<TableCell>{item.ur_phonenumber}</TableCell>



<TableCell align="right">


<div className="actionbtns">
<Button variant="text" className="editbutton"  onClick={()=>{this.Useraddbox(item.ur_id)}} startIcon={<EditIcon />}>
Edit
</Button>

 {item.ur_role!=="1" && (
 
<Button variant="text"   className="deletebutton"  onClick={()=>{this.userDelete(item.ur_id)}} startIcon={<DeleteIcon />}>
  Delete
</Button> 

 )}
 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
         rowsPerPageOptions={[20,50,100,150]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}  
      />   
  
    
  </Paper></div>
 </React.Fragment> 

 
  );
}
}


export  class  AddUserbox extends Component {

   constructor(props) {
      super(props)
	  
          this.state = {redirect: false,open:false,
     userdata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:5,rowsPaging:[],
	 errormessage:"",erropen:false,btnsavedisbaled:false,btnsavetxt:'Save',
	  order:'desc' ,orderBy:'',name:'',urpassword:'',phonenumber:'',role:'',username:'',
	          
      }
      
  }
  
 Usergetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxuser.php?action=getall&ur_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
      ur_id:resp.data[0].ur_id,
	  name: resp.data[0].ur_name,
     
		phonenumber: resp.data[0].ur_phonenumber, 
		username: resp.data[0].ur_username, 
		
		role:resp.data[0].ur_role, 		
		urpassword:resp.data[0].ur_password, 		
      
    
    });
	
  }
  
});
	  
  }else{
	 this.setState({  
	  name:'',
	 username:'',
	Phonenumber:'',
	 ur_id:0,
	 role:'',
	 password:'',
	 
	  }); 
 }
  
    }
  

handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,  name:'',username:'',brancharray:[],branch:'',phonenumber:'',passwords:'',role:'', 
     errormessage:'',erropen:false, Admin:'', colibrary:'',librarycoordinate:'',
	  });
		this.Usergetdata();
};



 
myChangeHandler = (event) => {
	
	  const re = /^[0-9\b]+$/;
  let nam = event.target.name;
  let val = event.target.value;  
 
  if(nam==="phonenumber")
  {
	  if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
	  
  }
  }
  else{
  this.setState({[nam]: val});
  }
  

}

 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }


SubmitForm= (e) => {

	let name = this.state.name;
	let phonenumber = this.state.phonenumber;
	let username = this.state.username;
	let urpassword = this.state.urpassword;
	let role = this.state.role;

	if(name===''){
						
		this.setState({errormessage:"Please Enter Name.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
		
					if(phonenumber===''){
						
				this.setState({errormessage:"Please Enter Phone Number",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				if(username===''){
						
				this.setState({errormessage:"Please Enter User Name",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}

				if(urpassword===''){
						
				this.setState({errormessage:"Please Enter password",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
					if(role===''){
						
				this.setState({errormessage:"Please Select Role",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
				

		this.setState({errormessage:"",erropen:false,btnsavedisbaled:true,btnsavetxt:'Processing...',});

				var formData = new FormData();
				if(this.props.id===0){
			    formData.append('action','insert')
				
				}
				
				else{
					formData.append('action','update')
					formData.append('ur_id',this.props.id)
				}
				
			
				

					formData.append('ur_name',name)
					formData.append('ur_phonenumber',phonenumber)
					formData.append('ur_username',username)
					formData.append('ur_password',urpassword)
					formData.append('ur_role',role)
				
					formData.append('userid',localStorage.getItem("KLUserid")) 
					axios.post(Serverurl+"ajaxuser.php", formData, {
				}).then(res => {
					
					
					
						if(res.data!=="-1")
					{
							
					
					  this.props.updateDonenew();
					this.setState({open:false,btnsavedisbaled:false,btnsavetxt:'Save',errormessage:"",erropen:false});
					
						
					
					}else{
						
				this.setState({open:false,btnsavedisbaled:false,btnsavetxt:'Save',errormessage:"Username already Exist",erropen:true});
					}
					
				
					
	} );

					
}
		



	


render (){

return(


<React.Fragment>




    <Dialog

	open={this.state.open} onClose={this.SubmitForm} maxWidth="sm" fullWidth="true" aria-labelledby="form-dialog-title">
      <div className="dialoguetitle"><h4> User</h4></div>
      <DialogContent>
	  
<div className="userdialogue">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>					  
		<ul >
			<li>
			<p><span className="validation">*</span>Name </p>
			<p><TextField name="name" 
		   onChange={this.myChangeHandler} 
			
		   className="inputcntrl"
		 fullWidth
		   value={this.state.name}
			 
			  InputLabelProps={{
		shrink: true,
		}}inputProps={{tabIndex: "1",maxLength:150}}
				
			/></p>
		</li>					
					

		<li>
		<p><span className="validation">*</span>Phone Number </p>
		<p><TextField name="phonenumber" 
		onChange={this.myChangeHandler} 
	    
			   className="inputcntrl"	
		 fullWidth
			
			value={this.state.phonenumber}
			 
		InputLabelProps={{
		shrink: true,
		}}inputProps={{tabIndex: "2",maxLength:15}}
			/></p>
		</li>					
			

				
		<li>
		<p><span className="validation">*</span>User Name </p>
		<p><TextField name="username" 
	   onChange={this.myChangeHandler} 
		   className="inputcntrl"
	   
	  fullWidth
	   value={this.state.username}
			inputProps={{tabIndex: "3",maxLength:150}}
			
			InputLabelProps={{
		shrink: true,
		}}
				/></p>
		</li>	
		
		<li>
	<p><span className="validation">*</span>Password </p>
		<p><TextField name="urpassword" 
		onChange={this.myChangeHandler} 
	   	type="password"
			   className="inputcntrl"
		 fullWidth
		value={this.state.urpassword}
		inputProps={{tabIndex: "4",maxLength:20}}
		ref={this.usernameInput}
			InputLabelProps={{
		shrink: true,
		}}
				/></p>
		</li>					
				
				
		<li>	
		<p><span className="validation">*</span>Role</p>
		<p><FormControl >			   
		<Select className="inputcntrl"
		variant="outlined" 	   
		native  autoWidth
		value={this.state.role}
		onChange={this.myChangeHandler}
		name='role'
		id='role'
		InputLabelProps={{
		shrink: true,
		}}inputProps={{tabIndex: "5"}}
		>
		<option value={0}>&nbsp;Role</option>
		<option value={1}>Admin</option>
		<option value={2}>Co-Ordinator</option>		
		</Select>
		</FormControl></p>	</li>						
		
		
						
			
	
		</ul>
		

		
		
       <div className="dialoguebtn">
			<ul>	  
			
			
		
		<li><Button  className="cancelbtn" onClick={this.handleClickOpen} color="secondary">
					Cancel
				</Button></li>
		<li><Button className="savebtn" disabled={this.state.btnsavedisbaled}  onClick={this.SubmitForm} color="secondary">
					{this.state.btnsavetxt}
		</Button></li>
				
				
			</ul>	
			
<div className="clear"></div>

 </div>     
			 
 </div> 		
 </DialogContent>
      
    </Dialog>

</React.Fragment>

) }
 
} 
  