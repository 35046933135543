import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles'; 
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';


import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default class Audiodisplayform extends Component{
	
    constructor(props) {
    super();
   
        this.state = {isChecked:false,setSelectedValues:'',trackIndex:0,checkboxes:'',categoryname:[],redirect: false,name:'',totaldata:[],ct_name:'',ca_id:'',
     af_ct_id:'',audiodata:[],categoryid:'',catname:'',categoryarray:[], audioarray:[],af_title:'',page:0,rowsPerPage:10
    


	 }
      
	     
	} 
	
onChange=(event)=>{

const isChecked=event.target.checked;
if(isChecked){
this.setState({categoryname:[this.state.categoryname,event.target.value]});
}
}

	myChangeHandler = (event) => {
	
	
  let nam = event.target.name;
  let val = event.target.value;  
 
  this.setState({[nam]: val});

}
 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	
   handleSubmit(event){
	   
	  
                event.preventDefault();
                this.state.setSelectedValues(this.state.checkboxes.join(','));
            };



 handleChange(event) {  
        var isChecked = event.target.checked;  
        var item = event.target.value;  
           
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));  
  }  

  
  
    
  
  componentDidMount()
	{		document.title = "Kalisu Foundation - Audio Library";
		
		if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
	this.handleClickSearch();

	
	
	 this.apicall(this.state.page,this.state.rowsPerPage);
	}
	
  
  
  


/*

  handleChangeCategory = (id,rowsd) => {
		
		
		  const updatedArray = [...this.state.categoryarray];

		
		  if(updatedArray[rowsd].selected==="0")
		  {
					  updatedArray[rowsd].selected = "1" ;
					  
					
				 	  
		  }
else{

			  updatedArray[rowsd].selected = "0" ;
			
			  
}




 this.setState({
       categoryarray: updatedArray,
		
		
    });


	
  }; 
	


categorybind=()=>
{
	
	
	axios.get(Serverurl+'ajaxcategory.php?action=getall')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						categoryarray:resp.data	
							}) 
			}
		
	   }) 
}*/

handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 
	
	
} 

handleClicaudiodisplay=()=>{
	
	
	 this.apicall(this.state.page,this.state.rowsPerPage); 
}

apicall(page,rowsPerPage){
	let straf_name="",strcatid="",strtype="",struserid="";
	let search = window.location.href;
let strcaid = this.getParameterByName('caid',search);
let strname = this.getParameterByName('key',search);
let strnew = this.getParameterByName('new',search);
let strplay = this.getParameterByName('play',search);
if(strname!==null)
{
		
	straf_name=	strname;
		
	}
	if(strcaid!==null)
{
		
	strcatid=	strcaid;
		
	}if(strnew!==null)
{
		
	strtype=	"1";
		
	}if(strplay!==null)
{
		
	strtype=	"2";
		
	}
	if(localStorage.getItem("KLRole")=="2")
	{
		struserid=localStorage.getItem("KLUserid");
	}else{
		strtype="";
	}

axios.get(Serverurl+'ajaxaudio.php?action=getall&af_title='+straf_name+'&userid='+struserid+'&type='+strtype+'&af_ct_id='+strcatid+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  audiodata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    },()=>{
				if(strcaid!==null){
			this.setState({ catname:resp.data[0]["ct_name"] });
		}
				
			});
		
     }
	 else{
		   this.setState({audiodata:[],totaldata:0});
	 }
 
    });

}
   handleClickPrevious = () => {
	   var self=this;
	   let currentTrack=0;
	   
	  this.setState({trackIndex:  currentTrack === 0 ? self.state.audiodata.length - 1 : currentTrack - 1});  
 
  };

   handleClickNext = () => {
	    var self=this;
	    let currentTrack=0;
	  this.setState({trackIndex:  currentTrack < self.state.audiodata.length - 1 ? currentTrack + 1 : 0});  
   
  };
onChangecategory=(caid)=>{
	let strsep=",";
	
	this.setState({ca_id:this.state.ca_id+strsep+caid} , () => {
	 this.apicall(this.state.page,this.state.rowsPerPage); 
	});
}
handleplay(afid)
{
	var formData = new FormData();
	 formData.append('action','loginsert')
	 formData.append('al_af_id',afid)
	 	   
formData.append('al_us_id',localStorage.getItem("KLUserid"))	
 axios.post(Serverurl+"ajaxaudio.php", formData, {
        }).then(res => {
			
			
		});
}
render(){
	return(
	
  <React.Fragment>	
	
		



	
	
			  
  <div className="contentbg">

		<div className="audiotitlelist">
		{this.state.catname!=="" && (<h2>{this.state.catname}</h2>)}
			{this.state.audiodata.length>0 &&(
			 <ul>
			  
			{this.state.audiodata.map((item,index) => { return ( 
			<li key={index}><a href={"#/Viewmorepage?aoid="+item.af_id}> 
			 
			<h3>{item.af_title} - <span className="playcnt">(<AudiotrackIcon className="iconvertical"/>{item.playcnt})</span> &nbsp; <ArrowForwardIcon  className="iconvertical"/></h3>
			</a>

     <AudioPlayer
        style={{ borderRadius: "1rem", color: "green", background: "#ececec" }}
        
        src={Serverurl+'uploads/'+item.af_file}
        onPlay={(e) => this.handleplay(item.af_id)}
        showSkipControls={false}
        showJumpControls={false}
        loop={false}
        onClickPrevious={this.handleClickPrevious}
        onClickNext={this.handleClickNext}
        onEnded={this.handleClickNext}
      />
			 
			 
			
			</li>
	 

			 );})}
			
			</ul>
			 )}{this.state.audiodata.length===0 &&(<p>No Records</p> )}
		</div>



</div>


	<div className="clear"></div>
 	  	</React.Fragment>		  
	

	);		
}
  }

  
  
  
  
  
  
  
  
  
  
  
  
  
  
 