import { BrowserRouter as Router, Route, Routes,NavLink,Outlet,Link,useLocation } from 'react-router-dom';
import React ,{Component} from 'react';
import axios from 'axios';import { Navigate  } from 'react-router-dom'; 
import Serverurl from './Apiurl';
import MenuList from '@mui/material/MenuList';
import Kalisulogo  from './images/logo.jpg';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';


import IconButton from '@mui/material/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
		import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CategoryIcon from '@mui/icons-material/Category';	
import PeopleIcon from '@mui/icons-material/People';
import HeadsetIcon from '@mui/icons-material/Headset';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export default class LayoutContent extends Component  {
	constructor(props) {
    super();
   
        this.state = {categoryarray:[],redirect:false,cid:'',pagename:'',year:new Date().getFullYear()}
	}
	
	
	
componentDidMount()
	{
this.categorybind();
	}
	categorybind=()=>
{
	
	
	axios.get(Serverurl+'ajaxcategory.php?action=menu')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						categoryarray:resp.data	
							}) 
			}
		
	   }) 
}

handlepage(cid){
	const { history } = this.props;
	
							
							window.location.replace('#/AudioLibrary?caid='+cid);
				window.location.reload('#/AudioLibrary?caid='+cid);			
}
render() {
	let caid=this.state.cid;
	if(this.state.redirect===true){
		return <Navigate to={this.state.pagename} />
	}
		return (
			<React.Fragment>
					
			<div className="box-tab">
		<div className="box-row">

<div className="menulist">

<div className="menuitem">
		 <MenuList>
	
	

	<React.Fragment>
		<a href={"#/Dashboard"}  > <ListItem button  >
		<ListItemIcon >
		<Tooltip  title="Dashboard">
		<IconButton aria-label="Dashboard">
	<HomeOutlinedIcon alt="Dashboard" />
		</IconButton>
		</Tooltip>
		</ListItemIcon>
		<ListItemText style={{"color":"#a3a3a3"}} primary="Dashboard" />
        </ListItem></a>
		  
   </React.Fragment>
   
   
   
   {localStorage.getItem("KLRole")==="1" && (
    <React.Fragment>
			<a href={"#/User"}  > <ListItem button  >
			<ListItemIcon >
			<Tooltip title="User Mangement">
			<IconButton aria-label="User Mangement">
			<PeopleIcon alt="User Mangement"/>
			</IconButton>
			</Tooltip>
		   </ListItemIcon>
			<ListItemText style={{"color":"#a3a3a3"}}   primary="User Mangement" />
          </ListItem></a>
		  
 
		<a href={"#/Category"}  > <ListItem button  >
		 <ListItemIcon >
		 <Tooltip title="Category  Management">
		 <IconButton aria-label="Category Management">
			<CategoryIcon alt="Category Management"/>
 
		</IconButton>
		</Tooltip>
		</ListItemIcon>
		<ListItemText  style={{"color":"#a3a3a3"}}  primary="Category  Management" />
          </ListItem></a>
		  
	
		<a href={"#/ManageAudio"}  > <ListItem button  >
		 <ListItemIcon >
		 <Tooltip title="Audio  Management">
		 <IconButton aria-label="Audio   Management">
		<HeadsetIcon alt="Audio Management"/>
		 
			</IconButton>
		 </Tooltip>
		   </ListItemIcon>
		   <ListItemText style={{"color":"#a3a3a3"}}   primary="Audio Management" />
				  </ListItem></a>
		  
   </React.Fragment>)}
		<React.Fragment>
			<a href={"#/AudioLibrary"}  > <ListItem button  >
			<ListItemIcon >
			<Tooltip title="Audio Library">
			<IconButton aria-label="Audio Library">
			<LibraryMusicIcon alt="Audio Library"/>
			</IconButton>
			</Tooltip>
		   </ListItemIcon>
			<ListItemText style={{"color":"#a3a3a3"}}   primary="Audio Library" />
          </ListItem></a>
		  
   </React.Fragment>
		<Divider />
		
	   
   
   
        	<div className="audiotitle">	
	 <ul>
			{this.state.categoryarray
								.map((item,index) => {  
						   return (
				 <React.Fragment>
				
				
				 
				 <li key={index}  onClick={()=>{this.handlepage(item.ct_id)}} >
			<ListItem button  >	<ListItemIcon ><IconButton aria-label="Audio Library">
			<KeyboardArrowRightIcon className="iconvertical"/>
				</IconButton> </ListItemIcon>
				
				<ListItemText style={{"color":"#a3a3a3"}}   primary={item.ct_name} />
		</ListItem>
				 </li>
			
				
			
			   
			 </React.Fragment>	
			);
			
<div className="clear"></div>
		})}  </ul></div> 
		
  
  
</MenuList>		
	</div>
		</div>
		
<div className="content">
				
				
				
				<div className="form">
				<div className="formsubmit">
				<Outlet context={{   }} />
				
				 </div></div></div>
				 
				 
				 </div></div>
				 
				 <div className="footerbg">
				 <p>©Copyright {this.state.year} Kalisu, Mysore. All rights reserved. </p>
				 </div>
		    </React.Fragment>
		);
	}
}
//const ShowTheLocationWithRouter = withRouter(Layouthead);

