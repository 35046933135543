import React, { Component } from "react";
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import audiocnt  from './images/icon-1.jpg';
import newaudiocnt  from './images/icon-2.jpg';
import playaudiocnt  from './images/icon-3.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import AudiotrackIcon from '@mui/icons-material/Audiotrack';
export default class Dashboard extends Component{
	
	constructor(props){
		super(props);
			this.state={totalaudio:0,newaudio:0,playaudio:0,latestdata:[]}
			
	}
	componentDidMount() {
		
		document.title = "Kalisu Foundation - Dashboard";
		if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
		this.DashboardCountApi();
	}
	DashboardCountApi(){
		
		let type="1";
		
		if(localStorage.getItem("KLRole")=="1")
		{
			type="2";
		}
		 axios.get(Serverurl+'ajaxaudio.php?action=dashboard&al_us_id='+localStorage.getItem("KLUserid")+'&type='+type)

  .then(resp => {  
		
    if(resp.data.length>0){
		this.setState({totalaudio:resp.data[0]["totalcnt"],newaudio:resp.data[0]["newcount"],playaudio:resp.data[0]["playedcount"]});
this.DashboardLatestApi();	}
  });
	}
	DashboardLatestApi(){
		
		 axios.get(Serverurl+'ajaxaudio.php?action=latestall&al_us_id='+localStorage.getItem("KLUserid")+'&type=1')

  .then(resp => {  
		
    if(resp.data.length>0){
		this.setState({latestdata:resp.data});
	}
  });
	}
	handleplay(afid)
{
	var formData = new FormData();
	 formData.append('action','loginsert')
	 formData.append('al_af_id',afid)
	 	   
formData.append('al_us_id',localStorage.getItem("KLUserid"))	
 axios.post(Serverurl+"ajaxaudio.php", formData, {
        }).then(res => {
			this.DashboardCountApi();
			
		});
}
	
	render() {
  return (
  
   <React.Fragment>
	  <ul className="dashbaordcount">
	  <li>
	  <div className="icon"><img src={audiocnt} /></div>
	   <div className="icontxt"><h3>{this.state.totalaudio}</h3><p>Total Audios</p></div></li>
	  <li><a href="#/AudioLibrary?new=1">
	  <div className="icon"><img src={newaudiocnt} /></div>
	 <div className="icontxt">  <h3>{this.state.newaudio}</h3><p>New Audios</p></div></a></li>
	  <li><a href="#/AudioLibrary?play=1"><div className="icon"><img src={playaudiocnt} /></div>
	  
	 <div className="icontxt">   <h3>{this.state.playaudio}</h3><p>Played Audio</p></div></a></li>
	  </ul>
	  
	  
	  <div className="contentbg">
	  	<div className="audiotitlelist">
<h2>Latest Audios</h2>
			{this.state.latestdata.length>0 &&(
			 <ul>
			  
			{this.state.latestdata.map((item,index) => { return ( 
			<li key={index}><a href={"#/Viewmorepage?aoid="+item.af_id}> 
			 
			<h3>{item.af_title} - <span className="playcnt">(<AudiotrackIcon className="iconvertical"/>{item.playcnt})</span> &nbsp; <ArrowForwardIcon  className="iconvertical"/></h3>
			</a>

     <AudioPlayer
        style={{ borderRadius: "1rem", color: "green", background: "#fff" }}
        
        src={Serverurl+'uploads/'+item.af_file}
        onPlay={(e) => this.handleplay(item.af_id)}
        showSkipControls={false}
        showJumpControls={false}
        loop={false}
        onClickPrevious={this.handleClickPrevious}
        onClickNext={this.handleClickNext}
        onEnded={this.handleClickNext}
      />
			 
			 
			
			</li>
	 

			 );})}
			
			</ul>
			 )}{this.state.latestdata.length===0 &&(<p>No New Audios</p> )}
		</div>
	  </div>
	  
	  </React.Fragment>

  );
	}
}
