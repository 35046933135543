import {  Route, Routes,Outlet, } from 'react-router-dom';
import React ,{Component} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Kalisulogo  from './images/logo.jpg';
import Divider from '@mui/material/Divider';

import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
class Layouthead extends Component {

	constructor(props) {
    super(props);
	 
	 this.state = {anchorEl:false,open:false,search:"Search",redirect:false,pagename:''}
	
	}
	handleprofileClose = (event) => 
{	
	this.setState({anchorEl: !this.state.anchorEl,open: !this.state.anchorEl});
}
 handleMouseDownSearch = event => {
    event.preventDefault();
	

  };componentDidMount() {
	  
	  document.addEventListener("keydown", this.onEnetrhandler, false);
  } componentWillUnmount(){
    document.removeEventListener("keydown", this.onEnetrhandler, false);
  }
  onEnetrhandler= (event) => {
	let nam = event.target.name;
    let val = event.target.value;
	
	this.setState({[nam]: val});
	 if(event.keyCode === 13) {
		 
		
		
		window.location.replace('#/AudioLibrary?key='+val);
				window.location.reload('#/AudioLibrary?key='+val);	
	 }
}
	render() {
		
	




		return (
			<React.Fragment>					
			<div className="box-tab">
		<div className="box-row">

<div className="menulist">
		
			<div className="masterlog">
				<img src={Kalisulogo}/>
			</div>
			
			<Divider />

</div><div className="content">			
		<div className="userdetails">
		<div className="main">
							
				<div className="dashboard">
				
					

						<h4><Outlet /></h4>
						<p><Breadcrumbs aria-label="breadcrumb">
						
						
						 
						  <Typography color="white">	Home > <Outlet /></Typography>
						</Breadcrumbs></p>
					
				</div>
					<div className="searchsec">

         
          <OutlinedInput
            id="outlined-adornment-password"
            type={'text' }  name="search"  onFocus={this.onEnetrhandler} 
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                 
                
                 
                  edge="end"
                >
                  { <SearchIcon />}
                </IconButton>
              </InputAdornment>
            }
           
          />
      
					
					</div>		
				<div className="userprofile">
						<p>Welcome</p>
						<h4>{localStorage.getItem("KLName")}</h4>
									
					
				</div>
					<div className="iconmenu">
<SettingsIcon className="iconcolor"  onClick={this.handleprofileClose}/>
									
									<Menu
        anchorEl={this.state.anchorEl}
        id="account-menu"
        open={this.state.open}
        onClose={this.handleprofileClose}
        onClick={this.handleprofileClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,top:'45px!important',right:'15.8%',left:'unset!important',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 50,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
        
        
        
        <MenuItem onClick={this.handleprofileClose}>
         <a href="#/Changepassword">  <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password</a>
        </MenuItem>
        <MenuItem onClick={this.handleprofileClose}>
         <a href="#/Logout"> <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout</a>
        </MenuItem>
      </Menu>
</div>		
				<div className="clear"></div>
					
	</div>
				
				
</div>
		</div>
		</div>	</div>	
		    </React.Fragment>
		);
	}
}
//const ShowTheLocationWithRouter = withRouter(Layouthead);
export default Layouthead;
