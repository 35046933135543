


//const Serverurl=["http://localhost/xampp/apoorva/kalisu/"];

const Serverurl=["http://libraryapp.kalisu.org/appfiles/"];
export default Serverurl;



