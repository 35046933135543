import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 

import Serverurl from './Apiurl';

import Button from '@mui/material/Button';
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default class AudioviewmoreForm extends Component{
	
    constructor(props) {
    super(props);
 
        this.state = {redirect: false,   play: false,pagename:'',latestdata:[],
      pause: true,af_id:0,

     audiodata:[],af_description:'',af_file:'',previocn:'',af_title:'',play:false,playcount:0
      }
	  
	
       this.url = window.location.href;;
    this.audio = new Audio(this.url);

	   
	}     
		DashboardLatestApi(){
		
		 axios.get(Serverurl+'ajaxaudio.php?action=latestall&al_us_id='+localStorage.getItem("KLUserid")+'&type=2')

  .then(resp => {  
		
    if(resp.data.length>0){
		this.setState({latestdata:resp.data});
	}
  });
	}
	
 getaudiodatabyid(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	
componentDidMount()
	{
		
	
	this.OnloadAudiodisplay();
	}
	
	
	OnloadAudiodisplay()
{
	let search = window.location.href;
let strnoid = this.getaudiodatabyid('aoid',search);
axios.get(Serverurl+'ajaxaudio.php?action=getall&af_id='+strnoid)
       .then(resp => { 		
			if(resp.data.length>0){
				
				this.setState({af_id:resp.data[0]["af_id"],
				af_title:resp.data[0]["af_title"],playcount:resp.data[0]["playcnt"],
				af_description:resp.data[0]["af_description"],
				af_file:resp.data[0]["af_file"]});
				this.DashboardLatestApi();
			}
	   });
}
handleplay(afid)
{
	var formData = new FormData();
	 formData.append('action','loginsert')
	 formData.append('al_af_id',afid)
	 	   
formData.append('al_us_id',localStorage.getItem("KLUserid"))	
 axios.post(Serverurl+"ajaxaudio.php", formData, {
        }).then(res => {
			
			
		});
}

	 play =()=>{
    this.setState({
      play: true,
      pause: false
    });
    console.log(this.audio);
    this.audio.play();
  }
  
  pause=()=>{
	  
  this.setState({ play: false, pause: true });
    this.audio.pause();
  }
	  handleClickPrevious = () => {
	   var self=this;
	   let currentTrack=0;
	   
	  this.setState({trackIndex:  currentTrack === 0 ? self.state.audiodata.length - 1 : currentTrack - 1});  
 
  };

   handleClickNext = () => {
	    var self=this;
	    let currentTrack=0;
	  this.setState({trackIndex:  currentTrack < self.state.audiodata.length - 1 ? currentTrack + 1 : 0});  
   
  }; handleClickOpen= (e) => {
 this.setState({redirect:true,pagename:'/AudioLibrary'});
}
	 
render() {
	if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

	let html=this.state.af_description  
  return (
    


	
	
  <React.Fragment>
  	

 
	<div className="contentbg"><div className="formHeading">
	<div className="pageheading"><h4>{this.state.af_title} -  <span className="playcnt">({this.state.playcount})</span> </h4>
						</div>
	<div className="backbutton">
	<Button  className="afbackbutton" variant="contained"  onClick={this.handleClickOpen}  >
			BACK
</Button>  
	</div><div className="clear"></div>
	</div>
	
 	
	  <AudioPlayer
        style={{ borderRadius: "1rem", color: "green", background: "#fff" }}
        
        src={Serverurl+'uploads/'+this.state.af_file}
        onPlay={() => this.handleplay(this.state.af_id)}
        showSkipControls={false}
        showJumpControls={false}
       
        onClickPrevious={this.handleClickPrevious}
        onClickNext={this.handleClickNext}
        onEnded={this.handleClickNext}
      />
<br/>
	  <div dangerouslySetInnerHTML={ { __html: html}} />
   

			{this.state.latestdata.length>0 &&(
			  <div className="audiotitlelist recent"><h2>Recently Played Audios</h2> <ul>
			  
			{this.state.latestdata.map((item,index) => { return ( 
			<li key={index}><a href={"#/Viewmorepage?aoid="+item.af_id}> 
			 
			<h3>{item.af_title} - <span className="playcnt">(<AudiotrackIcon className="iconvertical"/>{item.playcnt})</span> &nbsp;&nbsp; <ArrowForwardIcon  className="iconvertical"/></h3>
			</a>

     <AudioPlayer
        style={{ borderRadius: "1rem", color: "green", background: "#fff" }}
        
        src={Serverurl+'uploads/'+item.af_file}
  
        showSkipControls={false}
        showJumpControls={false}
        loop={false}
        onClickPrevious={this.handleClickPrevious}
        onClickNext={this.handleClickNext}
        onEnded={this.handleClickNext}
      />
			 
			 
			
			</li>
	 

			 );})}
			
			</ul>	</div>
			 )}
	
    </div>
  </React.Fragment>
  

);
}	
	
	
	
}