import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';











import SearchIcon from '@mui/icons-material/Search';
 

import MusicNoteSharpIcon from '@mui/icons-material/MusicNoteSharp';

const headCells = [

	{ id: 'af_title', numeric: false, label: 'Title' },
 
 { id: '', numeric: false, label: '' },
 ]
 
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}

function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
 function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});


export default class Audioparent extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,
     audiodata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],name:'',
	 btnsrchdisbaled:false,btnsearchtxt:'Serach',
	 order:'desc' ,orderBy:'',af_title:'',af_description:'',pagename:'',
	          
      }
	   this.updateDonenewParent = this.updateDonenewParent;
	}
	  
	

	updateDonenewParent = (text) => {this.componentDidMount();  }

	
	
myChangeHandler = (event) => {
		
	
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
} 	


handleClickSearch=()=>{   
 
  
this.setState({  
      page: 0,
      rowsPerPage:20,btnsrchdisbaled:true,btnsearchtxt:'Processing...',
	  prevpage:0,Userdata:[]
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});
	
	
} 




	





	componentDidMount() {
		document.title = "Kalisu Foundation - Audio Management";
		if(localStorage.getItem("KLUserid")===null)
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}else{
			if(localStorage.getItem("KLRole")!=="1")
		{
			this.setState({
				redirect:true,
				pagename:'/Login'
			})
		}
			
		}
	localStorage.removeItem("KLafid")
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);

} 

	
apicall(page,rowsPerPage){
	
	

 
			 axios.get(Serverurl+'ajaxaudio.php?action=getall&af_title='+this.state.name+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  audiodata: resp.data, btnsrchdisbaled:false,btnsearchtxt:'Search', 
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({audiodata:[],totaldata:0, btnsrchdisbaled:false,btnsearchtxt:'Search', });
	 }
 
    });
}

	handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage); });
   
 
    
  };  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage); }); 
	
	
  }; 
  	
    handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

AudioEdit(afid){ 


	localStorage.setItem("KLafid",afid);

							
this.setState({redirect:true,pagename:'/Audiofileinsert'});
				
       
}
		
		
		
		
		


 
   AudioDelete(afid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('af_id',afid)
	 
		   
formData.append('userid',localStorage.getItem("KLUserid"))	
	
	 axios.post(Serverurl+"ajaxaudio.php", formData, {
        }).then(res => {
    
             this.setState({  
  page: 0,
      rowsPerPage:20,
	  prevpage:0,audiodata:[]
    
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage); });
   
 

  
    
            });
 }
      }
 



handleClickOpen= (e) => {
 this.setState({redirect:true,pagename:'/Audiofileinsert'});
}

render() {
	
	if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

return(


 
 <React.Fragment>
 

  <div className="contentbg toppad">


<div className="userserach">
			<ul>
				
						
<li><p>Title</p>
			<p ><TextField
					
				className="inputcntrl"			
             value={this.state.name}
			inputProps={{tabIndex: "1",maxLength:150}}
			
           name="name"  onChange={this.myChangeHandler}
			
          /></p>
	</li>
		
			
			
			
		
		<li>	<p>&nbsp;</p><p><Button className="searchbtn" disabled={this.state.btnsrchdisbaled} variant="contained" onClick={this.handleClickSearch}  >
{this.state.btnsearchtxt}
			</Button></p></li>

	<li>	<p>&nbsp;</p><p><Button className="Newbtn" variant="contained"  onClick={this.handleClickOpen}  endIcon={<MusicNoteSharpIcon className="iconvertical"/>} >
			Add Audio
		</Button> </p></li>				
		</ul>


			
				
		
		</div>
 		






 

		

<div className="tabledisplay">

  <TableContainer className={useStyles.container}>  
  
  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.audiodata.length}
            />
                      <TableBody>
                     {stableSort(this.state.audiodata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.af_title}</TableCell>

<TableCell align="left">


	

<div className="actionbtns">


<Button variant="text"  className="editbutton"   onClick={()=>{this.AudioEdit(item.af_id)}}  startIcon={<EditIcon />}>
Edit
</Button>

 
<Button variant="text"   className="deletebutton"  onClick={()=>{this.AudioDelete(item.af_id)}}   startIcon={<DeleteIcon />}>
  Delete
</Button> 



 
 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colSpan="2">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[5, 10, 15, 20, 25]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   

    


</div>

</div>
 </React.Fragment>

);


		} 
	
	
	
	
	}
	
	
	
	
	



   
   	

  
	
	
	

  
