
import { Navigate  } from 'react-router-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import Login from "./components/Login";
import User from "./components/User";
import Category from "./components/Category"; 
import ManageAudio from "./components/Audiofile";
import Logout from "./components/Logout";
import Audiofileinsert from  "./components/Audiofileinsert";
import AudioLibrary from  "./components/Audiodisplay";
import Viewmorepage from  "./components/Viewmorepage";
import Layoutcontent from "./components/Layoutcontent";
import Layouthleftheader from "./components/Layouthleftheader";
import Dashboard from "./components/Dashboard";
import Changepassword from "./components/Changepassword";
import './App.css';
import './components/styles/style.css';



class App extends Component {
	 constructor(props) {
    super(props);
   
     this.state = {name:''}
	 }
	handleCallback = (childData) => {
        // Update the name in the component's state
        this.setState({ name: childData })
    }
  render() {
	 
    return (
	
	
	
	<Router basename="/">
	<Routes>
	
		   <Route element={<Layouthleftheader />}>
			  <Route path="/User" element="User Management" />
			<Route path="/Category" element={"Category Management"} />
			<Route path="/ManageAudio" element={"Audio Management"} />
			<Route path="/Audiofileinsert" element={"Audio Management"} />
				<Route path="/Changepassword" element={"Change Password"} />
				<Route path="/AudioLibrary" element={"Audio Library"} />
				<Route path="/Dashboard" element={"Dashboard"} />
				<Route path="/Viewmorepage" element={"Audio Library"} />
				
				
			</Route>
			 
			
	<Route exact path="/"  element={<Navigate to="/Login" />}  />
			
			<Route path="/Login" element={<Login/>}/>
			<Route path="/Logout" element={<Logout/>}/>
		
		 </Routes>
	  <Routes>
	  <Route element={<Layoutcontent />}>
			<Route path="/User" element={<User />} />
			
			<Route path="/Category" element={<Category title="Category Management"/>}/>
			<Route path="/ManageAudio" element={<ManageAudio title="Audio Management"/>} />
			<Route path="/Changepassword" element={<Changepassword title="Change Password"/>} />
			<Route path="/Audiofileinsert" element={<Audiofileinsert title="Audio Management"/>} />
			<Route path="/AudioLibrary" element={<AudioLibrary title="Audio Library"/>} />

		<Route path="/Dashboard" element={<Dashboard title="Dashboard"/>} />
			<Route path="/Viewmorepage" element={<Viewmorepage title="Audio Library"/>} />
			</Route>
	   </Routes>
	  
	</Router>
		
  )}
}
export default App;
